import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import whiteboardPic from '../assets/images/whiteboard-jr.jpg'
import littlebitPic from '../assets/images/littlebit-jr.jpg'
import drawingPic from '../assets/images/drawing-jr.jpg'
import productPic from '../assets/images/product-jr.jpg'

class Jr extends React.Component {
    render() {
        const siteTitle = "NeuroFit Jr";

        return (
            <Layout>
                <Helmet title={siteTitle} />
                <section id="header" class="header-jr">
                    <div className="inner">
                        <span className="icon major fas fa-brain"></span>
                        <h1>
                          NeuroFit Jr
                        </h1>
                        <p>
                          the scientific way to give your child a <span class="highlight">neurological headstart</span>
                        </p>
                        <ul className="actions">
                          <li><a href="#one" className="button scrolly">Learn More</a></li>
                        </ul>
                    </div>
                </section>

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Neuroplasticity - brain play-doh</h2>
                            </header>
                            <p>
                              The brain isn’t a stiff, rigid computer — it can be molded and adapted <sup><a href="https://www.youtube.com/watch?v=xzbHtIrb14s&feature=youtu.be">[1]</a></sup>. This property of the brain is known as <span class="highlight">neuroplasticity</span> and the NeuroFit Jr utilizes this property to optimize your child's learning and physical growth. NeuroFit Jr is the first consumer product to implement cutting-edge, non-invasive <span class="highlight">transcranial direct current stimluation (tDCS)</span> technology specificially for children. Clinical studies have shown tDCS' ability to improve working memory <sup><a href="https://scholar.google.com/scholar_lookup?title=Anodal%20transcranial%20direct%20current%20stimulation%20of%20prefrontal%20cortex%20enhances%20working%20memory&author=F.%20Fregni&author=PS.%20Boggio&author=M.%20Nitsche&author=F.%20Bermpohl&author=A.%20Antal&author=E.%20Feredoes&journal=Exp%20Brain%20Res&volume=166&pages=23-30&publication_year=2005#d=gs_qabs&p=&u=%23p%3Db0dk1caw9cgJ">[2]</a></sup> and reduce symptoms of cerebal palsy in children<sup><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5007190/">[3]</a></sup>. Today, Neurofit is making these possible benefits accessible for all families.
                            </p>

                            <p>Children that wear the Neurofit Jr while performing stimulating activities (e.g. reading, drawing, exercising) turbocharge their brain circuitry's ability to perform these activities and <span class="highlight">improve 20% faster</span> than their peers<sup>*</sup></p>

                            <p><a href="#" className="button">Buy</a></p>

                            <p class="fine-print">*Manufacturer claims not verified by FDA, please use Neurofit Jr as directed.</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={whiteboardPic} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fas fa-child"></span></li>
                                <li><span className="icon style2 major fas fa-bolt"></span></li>
                                <li><span className="icon style3 major fas fa-tachometer-alt"></span></li>
                                <li><span className="icon style4 major fas fa-clipboard-check"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                              <h2>Safe Science<sup>*</sup></h2>
                            </header>
                            <p>
                              Our team has built a tDCS device is so safe from the known physical harms of existing tDCS devices in the market that a child can wear it. Current studies also show no evidence of significant harm to users of tDCS devices<sup><a href="https://www.ncbi.nlm.nih.gov/pubmed/27372845">[4]</a></sup>. The Neurofit Jr is so comfortable and fun, your child won't even notice the slight electrical tingle on his or her head.
                            </p>

                            <ul>
                              <li>Safe electrical stimulation amplitude of 1mA<sup><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5007190/">[5]</a></sup></li>
                              <li>No electrode skin burns</li>
                              <li>Safer<sup>*</sup> than licking a 9V battery</li>
                            </ul>

                            <p class="fine-print">*Long term side effects, including unintended and irreversible alterations to other neurological brain functions, may occur.<sup><a href="https://onlinelibrary.wiley.com/doi/full/10.1002/ana.24689">[6]</a></sup></p>
                        </div>
                    </div>
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>Kid-petitive Advantage</h2>
                            </header>
                        </div>

                        <div className="col-6">
                            <span className="image fit"><img src={drawingPic} alt="" /></span>
                            <h3>Enhanced Play</h3>
                            <p>Neurofit Jr boost your child's brain activity by electrically stimulating and turbocharging the synapses between neuron paths — paths that are typically activated during play or learning.</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={littlebitPic} alt="" /></span>
                            <h3>Optimized Learning</h3>
                            <p>Neurofit Jr targets regions of the brain associated with learning and motor control (voluntary movement) — accelerating your child's gains from learning and playing.</p>
                        </div>
                    </div>
                </section>

                <section id="four" className="main style2 special">
                    <div className="container">
                        <header className="major">
                            <h2>Ready to optimize your child's future?</h2>
                        </header>
                        <ul className="actions uniform">
                            <li><a href="#" className="button special">Buy</a></li>
                            <li><a href="https://www.adrianbautista.com/itp-blog/citizen-science/week-11/" className="button">Learn More</a></li>
                        </ul>
                    </div>
                </section>

                <section id="five" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Are you an investor?</h2>
                            </header>
                            <p>
                              Learn more about how you can participate in this growing USD $3 billion industry. See our long term vision and corporate social responsibility initiative to bring Neurofit Jr to communities of all socio-economic backgrounds to equalize adolescent experience and opportunities. Help us drive and define the future of optimizing human brain function.
                            </p>

                            <p><a href="#" className="button">Invest With Us</a></p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={productPic} alt="" /></span>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Jr;
